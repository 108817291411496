import styled from '@emotion/styled'
import { Events } from 'app/components/Events'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { Location } from 'app/components/Location'
import { MainParagraph } from 'app/components/MainParagraph'
import { Mosaic } from 'app/components/Mosaic'
import { Paragraph } from 'app/components/Paragraph'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Visual } from 'app/components/Visual'
import { ContactsForm } from 'app/containers/ContactsForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import {
  ContactsFormSenderBackend,
  ContactsFormSenderBackendConfiguration,
} from 'app/utils/ContactsFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  contactsFormSenderBackendConfiguration: ContactsFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      ContactsFormSenderBackend(
        pageContext.contactsFormSenderBackendConfiguration,
      ),
    [pageContext.contactsFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero languageCode={pageContext.languageCode} {...context.heroProps} />
      ) : null}
      {context.mainParagraphProps ? (
        <MainParagraph {...context.mainParagraphProps} />
      ) : null}
      {context.introProps ? (
        <Intro sectionID="alma" {...context.introProps} />
      ) : null}
      {context.paragraphProps ? (
        <Paragraph sectionID="kitchen" {...context.paragraphProps} />
      ) : null}
      {context.visualProps ? <Visual {...context.visualProps} /> : null}
      {context.eventsProps ? (
        <Events sectionID="events" {...context.eventsProps} />
      ) : null}
      {context.locationProps ? (
        <Location sectionID="location" {...context.locationProps} />
      ) : null}
      {context.mosaicProps ? (
        <Mosaic sectionID="gallery" {...context.mosaicProps} />
      ) : null}
      {context.contactsFormProps ? (
        <ContactsForm
          sectionID="info"
          {...(context.contactsFormProps ? context.contactsFormProps : null)}
          onContactsFormSendToBackend={async (data) =>
            await sendFormToBackend(data)
          }
        />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
