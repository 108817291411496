import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  cards: CardProps[]
  description?: string
  label?: string
  sectionID?: string
  subtitle?: string
  title?: string
}

export const Events = memo(function Events({
  cards,
  description,
  label,
  sectionID,
  subtitle,
  title,
}: Props) {
  if (cards.length < 1) {
    return null
  }
  return (
    <Container
      id={`section-${sectionID}`}
      tag="section"
      wrap
      row
      space="between"
      dial={2}
    >
      <Text>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Text>
      {cards.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  margin: 12.5rem 0 11.5rem;
  padding: 0 9.722vw;
  height: 100%;
  align-items: flex-start;

  > div:nth-of-type(3),
  > div:nth-of-type(5) {
    top: -8.75rem;
  }

  @media (max-width: 1439px) {
    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: -6rem;
    }
  }

  @media (max-width: 1199px) {
    margin: 6.25rem 0 5.25rem;

    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: auto;
    }
  }

  @media (max-width: 991px) {
    padding: 0 1.875rem;
    margin-bottom: 1.875rem;
  }
`

const Text = styled.div`
  position: relative;
  z-index: 2;
  max-width: 41.875rem;
  padding-bottom: 5rem;
  padding-right: 10vw;
  width: calc(50% - 0.5rem);

  @media (max-width: 1439px) {
    padding-right: 5vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    max-width: none;
    padding-bottom: 3.25rem;
    padding-right: 0;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding-bottom: 1.875rem;
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  font-weight: 500;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
  text-transform: uppercase;
  margin-top: 2.25rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-style: italic;
  font-size: 1.125rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 3.75rem;

  @media (max-width: 1439px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    margin-left: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    text-align: center;
  }
`
