import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Item = memo(function Item({ icon, label }: Props) {
  return (
    <Container row dial={4}>
      {icon ? <Icon src={icon} alt={label} width="24" height="24" /> : null}
      {label ? <Label className="icon-label">{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  margin: 1.875rem 0 0 0;

  > img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.5rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight};
    transition: 0.2s ease-out;
  }

  @media (max-width: 767px) {
    > img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1.25rem;
    }
  }
`

const Icon = styled.img`
  width: 3.5rem;
`

const Label = styled.span`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;

  @media (max-width: 767px) {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
`
