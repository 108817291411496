import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'

const AUTOPLAY_TIMER = 5000

export interface Props {
  images?: ImageProps[]
  languageCode: string
  mobileImage?: ImageProps
}

export const Hero = memo(function Hero({
  images,
  languageCode,
  mobileImage,
}: Props) {
  if (!images) {
    return null
  }

  if (images.length < 1) {
    return null
  }

  const [lineRef, isLineVisible] = useInView({ threshold: 0.5, delay: 400 })

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState<boolean[]>([])
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: images.length,
    loop: true,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      images.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <>
      {mobileImage ? (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={mobileImage.src}
            imageSrcSet={mobileImage.srcSet}
          />
        </Helmet>
      ) : null}

      <Container>
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            <Picture {...mobileImage} />
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <Slider ref={sliderRef}>
              {uniq(images).map((item, index) => (
                <Slide
                  key={index}
                  className={currentSlide === index ? 'active' : undefined}
                  style={
                    index !== 0
                      ? {
                          opacity: opacities[index],
                        }
                      : { opacity: 1 }
                  }
                >
                  <Image
                    {...item}
                    media="(min-width: 2800px)"
                    src={loaded[index] ? item.src : undefined}
                    srcSet={loaded[index] ? item.srcSet : undefined}
                  />
                </Slide>
              ))}
            </Slider>
            <>
              {images.length > 1 ? (
                <Dots>
                  {images.map((item, index) => (
                    <Dot
                      key={index}
                      className={currentSlide === index ? 'active' : undefined}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(index)
                      }}
                    />
                  ))}
                </Dots>
              ) : null}
            </>
          </Media>
        </MediaContextProvider>
        <Wrapper>
          <FadeInUp>
            <SiteName
              className={isLineVisible ? 'line visible' : 'line'}
              ref={lineRef}
            >
              {useVocabularyData('ristorante-alma', languageCode)}
            </SiteName>
          </FadeInUp>
        </Wrapper>
        <ButtonScroll
          dial={5}
          onClick={() =>
            window.scroll({
              top: window.innerHeight - 90,
              left: 0,
              behavior: 'smooth',
            })
          }
        >
          scroll
        </ButtonScroll>
      </Container>
    </>
  )
})

const Container = styled.section`
  height: 100vh;
  position: relative;
  border-left: 4.375rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  border-right: 4.375rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  border-bottom: 4.375rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};

  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 2;
  }
  &:after {
    height: calc(100vh - 11.25rem);
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 0.7)}
    );
    bottom: 0;
  }

  @media (max-width: 1199px) {
    border-width: 4.861vw;
    border-bottom-width: calc(4.861vw + 3.5rem);
  }

  @media (max-width: 991px) {
    border-width: 1.875rem;
    border-bottom-width: 5.375rem;
    max-height: -webkit-fill-available;
  }

  @supports (-webkit-touch-callout: none) and (max-width: 1024px) {
    border-bottom-width: 1.875rem;
    max-height: -webkit-fill-available;
  }
`

const Picture = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    transform: scale(1.2);
  }
`

const Dots = styled.div`
  position: absolute;
  top: calc(50% + 3.4375rem);
  right: 4.583vw;
  z-index: 4;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    top: 50%;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin-top: 1.25rem;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`
const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: calc(50% + 3.4375rem);
  right: 5.556vw;
  left: 5.556vw;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1199px) {
    top: 50%;
  }
`

const SiteName = styled.span`
  position: relative;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 4.375rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.25rem 0;
  transition: 0.6s ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.0625rem;
    background: ${rgba(theme.colors.variants.neutralLight4, 0.2)};
    transition: all ease-in-out 1s 0.5s;
  }

  &:before {
    top: 0;
    left: 0;
  }
  &:after {
    bottom: 0;
    right: 0;
  }

  &.visible {
    &:before,
    &:after {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  @media (max-width: 574px) {
    display: block;
    font-size: 2rem;
    line-height: 2rem;
    padding: 0;

    &:before,
    &:after {
      content: none;
    }
  }
`

const ButtonScroll = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  position: absolute;
  top: calc(100vh - 8.125rem);
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @media (max-width: 1199px) {
    top: auto;
    bottom: calc(4.861vw + 5.375rem);
  }
`
