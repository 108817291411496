import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images?: ImageProps[]
  label?: string
  subtitle?: string
  sectionID?: string
  title?: string
}

export const Intro = memo(function Intro({
  description,
  images,
  label,
  sectionID,
  subtitle,
  title,
}: Props) {
  return (
    <Container
      id={`section-${sectionID}`}
      row
      tag="section"
      wrap
      space="between"
    >
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          <Head dial={8} row>
            <Text>
              {label ? (
                <FadeInUp>
                  <Label>{label}</Label>
                </FadeInUp>
              ) : null}
              {title ? (
                <FadeInUp>
                  <Title>{title}</Title>
                </FadeInUp>
              ) : null}
              {subtitle ? (
                <FadeInUp>
                  <Subtitle>{subtitle}</Subtitle>
                </FadeInUp>
              ) : null}
            </Text>
            <Line />
          </Head>
          <Wrap row dial={4} wrap>
            {images && images[0] ? (
              <LazyLoadComponent>
                <Image {...images[0]} />
              </LazyLoadComponent>
            ) : null}
            {images && images[1] ? (
              <LazyLoadComponent>
                <Image {...images[1]} />
              </LazyLoadComponent>
            ) : null}
            {images && images[2] ? (
              <LazyLoadComponent>
                <Image {...images[2]} />
              </LazyLoadComponent>
            ) : null}
          </Wrap>
          <Footer dial={3} row>
            <Line />
            {description ? (
              <FadeInUp>
                <Description
                  className="intro-desc"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
          </Footer>
        </Media>
        <Media lessThan="desktopSmall">
          <Head dial={8} row>
            <Text>
              <Line />
              {label ? (
                <FadeInUp>
                  <Label>{label}</Label>
                </FadeInUp>
              ) : null}
              {title ? (
                <FadeInUp>
                  <Title>{title}</Title>
                </FadeInUp>
              ) : null}
              {subtitle ? (
                <FadeInUp>
                  <Subtitle>{subtitle}</Subtitle>
                </FadeInUp>
              ) : null}
            </Text>
          </Head>
          <Footer dial={3} row>
            <Line />
            {description ? (
              <FadeInUp>
                <Description
                  className="intro-desc"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
          </Footer>
          <Wrap row dial={4} wrap>
            {images && images[0] ? (
              <LazyLoadComponent>
                <Image {...images[0]} />
              </LazyLoadComponent>
            ) : null}
            {images && images[1] ? (
              <LazyLoadComponent>
                <Image {...images[1]} />
              </LazyLoadComponent>
            ) : null}
            {images && images[2] ? (
              <LazyLoadComponent>
                <Image {...images[2]} />
              </LazyLoadComponent>
            ) : null}
          </Wrap>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  padding: 0 9.722vw;

  > div {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 20.833vw;
    height: 100%;
    top: 13.125rem;
    right: -3.472vw;
    background-image: url('./flower-dx.svg');
    background-repeat: no-repeat;
    z-index: 1;
  }

  @media (max-width: 1199px) {
    &:after {
      content: none;
    }
  }

  @media (max-width: 991px) {
    padding: 0 1.875rem;
  }
`

const Head = styled(FlexBox)`
  position: relative;
  z-index: 2;
  width: 50%;
  margin-bottom: 6.5rem;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 3.125rem;
    margin-bottom: 1.875rem;

    .line {
      margin: 0 auto 3.125rem;
    }
  }
`

const Footer = styled(FlexBox)`
  width: 50%;
  margin-top: 6.5rem;
  margin-left: auto;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 3.125rem;

    .line {
      display: none;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1.875rem;
  }
`

const Text = styled.div`
  padding-right: 16.667vw;

  @media (max-width: 1199px) {
    padding-right: 0;
    text-align: center;
  }
`

const Wrap = styled(FlexBox)`
  position: relative;
  z-index: 2;
  margin-right: -1.4%;

  picture {
    width: 31.9333%;
    height: 41.667vw;
    margin-right: 1.4%;
    position: relative;

    &:nth-of-type(2) {
      height: 26.111vw;
    }

    &:nth-of-type(1) {
      margin-bottom: -15.556vw;
    }
    &:nth-of-type(3) {
      margin-top: -15.556vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    margin-right: -1%;

    picture {
      width: 49%;
      margin-right: 1%;
      position: relative;
      height: 50vh;

      &:nth-of-type(1) {
        margin-bottom: 0;
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        margin-top: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    picture {
      height: 60vw;
    }
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  font-weight: 500;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  text-transform: uppercase;
  line-height: 4.375rem;
  margin-top: 2.25rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`
const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-style: italic;
  font-size: 1.125rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  font-weight: 300;
  margin-left: 6.528vw;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1199px) {
    margin-left: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    text-align: center;
  }
`
