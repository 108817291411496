import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  languageCode: string
  title?: string
}

export const Card = memo(function Card({ image, languageCode, title }: Props) {
  return (
    <Container>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper className="wrapper" dial={4} row wrap>
        {title ? <Title>{title}</Title> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  display: block;
  width: calc(50% - 0.5rem);
  height: 66.667vh;
  min-height: 37.5rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  &:before {
    content: '';
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1439px) {
    height: 50vh;
    min-height: 31.25rem;
  }

  @media (max-width: 1199px) {
    height: calc(50vw - 9.722vw - 0.5rem);
    min-height: auto;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 75vw;
    margin-bottom: 1.875rem;
  }
  @media (max-width: 767px) {
    height: calc(100vw - 3.75rem);
  }
`

const Wrapper = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  right: 4.167vw;
  bottom: 3.75rem;
  left: 4.167vw;
  transition: 0.3s ease-in-out;
  z-index: 2;
  padding-bottom: 0.75rem;
  border-bottom: 0.0625rem solid
    ${rgba(theme.colors.variants.neutralLight4, 0.2)};

  @media (max-width: 1199px) {
    bottom: 3.125rem;
  }

  @media (max-width: 767px) {
    right: 1.875rem;
    left: 1.875rem;
    bottom: 1.875rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`
