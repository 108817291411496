import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Close } from 'app/components/Common/Modal/Close'
import { Plus } from 'app/components/Icons'
import { theme } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Gallery } from './Gallery'

export interface Props {
  images: ImageProps[]
  label?: string
  languageCode: string
  sectionID?: string
  siteName?: string
  thumbnails?: ImageProps[]
  title?: string
}

export const Mosaic = memo(function Mosaic({
  images,
  label,
  languageCode,
  sectionID,
  siteName,
  thumbnails,
  title,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Head>
        <Line />
        {label ? (
          <FadeInUp>
            <Label id={`section-${sectionID}`}>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </Head>

      {thumbnails ? (
        <Wrapper>
          <Thumbs
            options={{
              percentPosition: true,
              transitionDuration: 0,
            }}
          >
            {uniq(thumbnails)
              .slice(0, 6)
              .map((item, index) => (
                <Thumb
                  key={index}
                  onClick={() => {
                    if (!modalGalleryStatus) {
                      setLocked(true)
                      setModalGalleryStatus(true)
                    } else {
                      setLocked(false)
                      setModalGalleryStatus(false)
                    }

                    setClickedSlide(index)
                    setTimeout(() => {
                      setClickedSlide(null)
                    }, 100)
                  }}
                >
                  <LazyLoadComponent>
                    <Image {...item} />
                    {index === 5 ? (
                      <CTA>
                        <Plus />
                        <CTALabel>
                          {useVocabularyData('look-at-all', languageCode)}
                        </CTALabel>
                      </CTA>
                    ) : null}
                  </LazyLoadComponent>
                </Thumb>
              ))}
          </Thumbs>
        </Wrapper>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
            siteName={siteName}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  padding: 0 4.861vw 6.25rem;
  text-align: center;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 60%;
    left: 0;
    background-image: url('./pattern.svg');
    z-index: 1;
  }

  @media (max-width: 1199px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 991px) {
    padding: 0 1.875rem 3.125rem;
  }
  @media (max-width: 767px) {
    padding: 0 1.875rem 1.875rem;
  }
`

const Head = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 50rem;
  margin: 0 auto 3.75rem;

  .line {
    margin: 0 auto 5.875rem;
    height: 20vh;
  }

  @media (max-width: 1199px) {
    margin: 0 auto 1.875rem;

    .line {
      margin: 0 auto 3.75rem;
      height: 20vh;
    }
  }
  @media (max-width: 991px) {
    .line {
      height: 15vh;
    }
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  text-transform: uppercase;
  font-size: 0.9375rem;
  letter-spacing: 0.1rem;
  line-height: 1.125rem;
  font-weight: 500;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 4.375rem;
  text-transform: uppercase;
  margin-top: 2.25rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`

const Wrapper = styled.div`
  padding-top: 1.0625rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 2;

  @media (max-width: 767px) {
    margin-top: -1.125rem;
  }

  @media (max-width: 1199px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 767px) {
    padding-bottom: 1.875rem;
  }
`

const Thumbs = styled(Masonry)`
  margin-right: -0.5rem;

  @media (max-width: 1199px) {
    margin-right: -0.5rem;
  }

  @media (max-width: 767px) {
    margin-right: -0.25rem;
  }
`

const Thumb = styled.div`
  width: calc(33.333% - 0.5rem);
  height: 22.569vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  margin: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  position: relative;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  &:nth-of-type(1),
  &:nth-of-type(3),
  &:nth-of-type(4) {
    height: 38.889vw;
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(33.333% - 0.5rem);
    margin: 0.5rem 0.5rem 0 0;
  }

  @media (max-width: 767px) {
    width: calc(33.333% - 0.25rem);
    margin: 0.25rem 0.25rem 0 0;
    height: 33.898vw;

    &:nth-of-type(1),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      height: 58.67vw;
    }
  }
`

const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backface-visibility: hidden;
  background: ${rgba(theme.colors.variants.neutralDark1, 0.5)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:hover {
    svg {
      transform: none;
    }
    span {
      opacity: 1;
    }
  }

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1199px) {
    svg {
      transform: none;
    }
  }
`

const CTALabel = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.125rem;
  opacity: 0.4;
  margin-top: 0.625rem;
  transition: 0.3s ease-in-out;

  @media (max-width: 767px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
